import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/seo/seo"
import Main from "../../components/layout/main"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import { Columns, Content, Footer, Rows } from "../../components/grid"
import Heading from "../../components/content/heading"
import Copy from "../../components/content/copy"
import CtaGroup from "../../components/containers/cta-group"
import Button from "../../components/interactive/button"
import SectionBackground from "../../components/containers/section-background"
import LogoRow from "../../components-smart/logo-row"
import Dropdown from "../../components-smart/interactive/dropdown"
import CtaBar from "../../components-smart/cta-bar"
import TryUsBar from "../../components/sections/try-us-bar"
import { Testimonials } from "../../components-smart/carousel"
import StatisticsSection from "../../components/sections/statistics-section"

function BusinessSolutions({ data, location }) {
  const {
    header,
    seo,
    logoRow,
    topSection,
    triColumnSection,
    stats,
    middleTextBlock,
    faq,
    cta,
    testimonials,
  } = data.strapiPageForBusiness

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <header>
        <Section>
          <SectionBackground>
            <div className="hero-image hero-image--business-solutions">
              <StaticImage
                src="../../images/business-solutions/hero.png"
                alt="Red panda baseball with meteor"
              />
            </div>
          </SectionBackground>
          <SectionContent hero paddingTop="lg">
            <Rows gap="xxl">
              <Columns count="2">
                <Content gap="xl" className="mt-lg mb-lg">
                  <Heading level={1}>{header.headingText}</Heading>
                  <Copy>
                    <p>{header.postHeadingText}</p>
                  </Copy>
                  <Footer>
                    <CtaGroup>
                      {header.buttons.map(
                        ({ color, link, text, type }, index) => (
                          <Button
                            key={`home-heading-button-${index}`}
                            href={link}
                            styletype={type}
                            color={color}
                          >
                            {text}
                          </Button>
                        ),
                      )}
                    </CtaGroup>
                  </Footer>
                </Content>
              </Columns>
              <Columns count="1">
                <LogoRow logoImageData={logoRow} />
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingBot="xs" paddingTop="sm">
          <Rows gap="lg">
            <Columns count="3,1">
              <Content>
                <Heading preheading={topSection.title.preHeading}>
                  {topSection.title.primaryHeading}
                </Heading>
              </Content>
            </Columns>
            <Columns count="2">
              <Content>
                <Copy contentToParse={topSection.leftColumn} />
              </Content>
              <Content>
                <Copy contentToParse={topSection.rightColumn} />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent>
          <Rows>
            <Columns count="2,1">
              <Content>
                <Heading preheading={triColumnSection.title.preHeading}>
                  {triColumnSection.title.primaryHeading}
                </Heading>
              </Content>
            </Columns>
            <Columns count="3">
              <Content>
                <div style={{ width: 128 }}>
                  <StaticImage
                    src="../../images/business-solutions/low-quality-clicks.png"
                    alt="Low quality clicks"
                  />
                </div>
                <Heading level={4}>
                  {triColumnSection.leftColumn.heading}
                </Heading>
                <Copy contentToParse={triColumnSection.leftColumn.text} />
              </Content>
              <Content>
                <div style={{ width: 128 }}>
                  <StaticImage
                    src="../../images/business-solutions/invalid-clicks.png"
                    alt="invalid clicks stop sign"
                  />
                </div>
                <Heading level={4}>
                  {triColumnSection.middleColumn.heading}
                </Heading>
                <Copy contentToParse={triColumnSection.middleColumn.text} />
              </Content>
              <Content>
                <div style={{ width: 128 }}>
                  <StaticImage
                    src="../../images/business-solutions/click-fraud.png"
                    alt="click fraud mouse"
                  />
                </div>
                <Heading level={4}>
                  {triColumnSection.rightColumn.heading}
                </Heading>
                <Copy contentToParse={triColumnSection.rightColumn.text} />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent>
          <Rows gap="lg">
            <Columns count="2" alignment="center">
              <Content>
                <StaticImage
                  src="../../images/business-solutions/cybersecurity-data-analytics.png"
                  alt="cyber security and data analytics"
                />
              </Content>
              <Content>
                <Heading preheading={middleTextBlock.heading.preHeading}>
                  {middleTextBlock.heading.primaryHeading}
                </Heading>
                <Copy contentToParse={middleTextBlock.text} />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent>
          <Rows>
            <Columns count="2">
              <Content gap="xxl">
                <Heading preheading={faq.title.preHeading}>
                  {faq.title.primaryHeading}
                </Heading>
                <Dropdown defaultSelected={1} content={faq.tab} />
              </Content>
              <Content paddingRight="none" paddingLeft="md">
                <StaticImage
                  src="../../images/agency-solutions/protection-privacy.png"
                  alt="Privacy locked files"
                />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <CtaBar reference="business-solutions" content={cta} />

      <StatisticsSection stats={stats} />

      <Section>
        <SectionContent>
          <Rows>
            <Columns count="1">
              <Content paddingLeft="sm">
                <Heading preheading={testimonials.testimonialsTitle.preHeading}>
                  {testimonials.testimonialsTitle.primaryHeading}
                </Heading>
              </Content>
              <Testimonials cardsData={testimonials.testimonials} />
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <TryUsBar />
    </Main>
  )
}

export const query = graphql`
  query BusinessSolutionsQuery {
    strapiPageForBusiness {
      seo {
        metaTitle
        metaDesc
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      header {
        buttons {
          type
          text
          link
          color
        }
        headingText
        postHeadingText
      }
      logoRow {
        logos {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alternativeText
        }
      }
      topSection {
        leftColumn
        rightColumn
        title {
          preHeading
          primaryHeading
        }
      }
      triColumnSection {
        title {
          preHeading
          primaryHeading
        }
        rightColumn {
          heading
          text
        }
        middleColumn {
          heading
          text
        }
        leftColumn {
          heading
          text
        }
      }
      middleTextBlock {
        heading {
          preHeading
          primaryHeading
        }
        text
      }
      faq {
        title {
          preHeading
          primaryHeading
        }
        tab {
          copy: faqTabText
          title: faqTabTitle
        }
      }
      cta {
        buttonText
        buttonLink
        tickOneText
        tickThreeText
        tickTwoText
        title
      }
      stats {
        statistics {
          diamondColor
          statDesc
          statText
          statTitleText
          includeCitation
          citationMark
          citationText
        }
        heading {
          preHeading
          primaryHeading
        }
        content
      }
      testimonials {
        testimonials {
          authorText
          link
          btnHex
          btnHexHover
          testimonialQuoteText
          logo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        testimonialsTitle {
          preHeading
          primaryHeading
        }
      }
    }
  }
`

export default BusinessSolutions
